import { observable } from 'mobx';
import { RequestState } from './common.interface';

export class CommonStore {
  @observable public authState: RequestState | undefined;
}

const commonStore = new CommonStore();

export default commonStore;
