import { Router } from '@reach/router';
import { Provider } from 'mobx-react';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import * as serviceWorker from './serviceWorker';
import * as stores from './stores';

const CRMInquiry = React.lazy(() => import('./routes/crm/Inquiry'));
const CRMReport = React.lazy(() => import('./routes/crm/AIReport/AIRoute'));
const SCEnvironment = React.lazy(() => import('./routes/sc/Environment/EnvironmentRoute'));
const SCParent = React.lazy(() => import('./routes/sc/ParentInquiry/ParentRoute'));
const SCTeachEnvironment = React.lazy(() => import('./routes/sc/TeachEnvironment/TeachEnvironmentRoute'));
const SCEducation = React.lazy(() => import('./routes/sc/EducationalAffairs/EducationalRoute'));
const CHILDDoctor = React.lazy(() => import('./routes/child/doctor/DoctorRoute'));
const CHILDParents = React.lazy(() => import('./routes/child/parents/ParentsRoute'));

ReactDOM.render(
  <Provider {...stores}>
    <Suspense fallback={<div>加载中...</div>}>
      <Router>
        <CRMInquiry path="/crm/inquiry" />
        <CRMReport path="/crm/report/*" />
        <SCEnvironment path="/sc/school/*" />
        <SCParent path="/sc/parent/*" />
        <SCTeachEnvironment path="/sc/teaching/*" />
        <SCEducation path="/sc/education/*" />
        <CHILDDoctor path="/child/doctor/*" />
        <CHILDParents path="/child/parents/*" />
      </Router>
    </Suspense>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister(); // 默认不启用
